@mixin tabbar-pc {
  @media screen and (min-width: 40rem) {
    @content;
  }
}

@mixin tabbar-mobile {
  @media screen and (max-width: 40rem) {
    @content;
  }
}

.tabbar {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 15;
  max-width: 100vw;
  background-color: #ddd;
  align-items: flex-start;

  @include tabbar-mobile {
    background-color: var(--color-bg);
    box-shadow: 0 0 6px hsla(0, 0, 0, 0.4);
  }

  @include on-dark-theme {
    background-color: #333;
  }

  @include on-sepia-theme {
    background-color: #dbd2c2;
  }
}

@mixin tabbar-m-interaction {
  @include reveal;
  @include rippleable;
}

.tabbar .tab {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 1rem 0.8rem;
  line-height: 1;
  display: flex;
  --color-ripple: var(--color-bg);

  color: inherit !important;
  text-decoration: none;

  @include tabbar-pc {
    @include reveal;
    @include rippleable;

    &:not(.back_home) {
      min-width: 8rem;
    }

    &[data-active="true"] {
      background-color: var(--color-bg);
    }
  }

  & > .tab_name {
    margin-right: 0.8rem;
  }

  & > .tab_close {
    @include icon-wrapper;
    @include reveal;
    @include rippleable;
    width: 1rem;
    height: 1rem;
    padding: 0;
    margin-left: auto;

    & > svg.icon {
      width: 1rem;
      height: 1rem;
    }

    @include tabbar-mobile {
      display: none;
    }
  }
}

.tabbar .back_home {
  flex-shrink: 0;
  @include icon-wrapper;
}

@include tabbar-mobile {
  .tabbar .tab_album_name:not(.active) {
    display: none;
  }
}

.menu-open-button {
  @include icon-wrapper();
  @include tabbar-m-interaction();
}

.tab_tune_container {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  min-width: 0;
  overflow-x: scroll;

  @include tabbar-mobile {
    .tab:not(.active) {
      display: none;
    }
  }
}

.tab_tune_container::-webkit-scrollbar {
  display: none;
}

.mob_tablist_open_button {
  @include rippleable;
  @include reveal;

  @include tabbar-pc {
    display: none;
  }

  @include tabbar-mobile {
    @include icon-wrapper;
  }
}
