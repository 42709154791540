#scores_container {
  @include fill-parent;
  z-index: 0;
  overflow-y: hidden;
}

.popup_message {
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  transition: 200ms ease-out;
  background-color: rgba(0, 0, 0, 0.6);
  color: var(--color-bg);

  @include on-dark-theme {
    background-color: rgba(255, 255, 255, 0.6);
  }

  padding: 1rem;
  font-size: 3rem;

  &.hidden {
    transform: translate(-50%, 0%);
    opacity: 0;
    pointer-events: none;
  }
}

.score {
  height: 100%;
  display: flex;
  position: relative; // for offsetLeft
  flex-wrap: nowrap;
  overflow-x: scroll;
  background-color: var(--color-bg);

  -ms-scroll-snap-type: proximity;
  -webkit-scroll-snap-type: proximity;
  scroll-snap-type: x mandatory;

  &[hidden] {
    display: none;
  }

  .score_loading_spinner_wrapper {
    position: relative;
    order: 998;
    min-width: 100%;

    .score > .svg_wrapper + & {
      @media screen and (orientation: landscape) {
        min-width: 50%;
        max-width: 50%;
      }
    }

    .loading_spinner {
      position: absolute;
      top: calc(50% - 20px);
      left: calc(50% - 20px);
    }
  }
}

.score::-webkit-scrollbar {
  width: 1px;
}

%score_jump {
  flex-shrink: 0;
  width: 5rem;
  background-color: #fafafa;
  margin: 1rem 0.5rem;

  @include on-dark-theme {
    background-color: #222;
  }
}

.score_jump_back {
  @extend %score_jump;
  order: 0;
}

.score_jump_forward {
  @extend %score_jump;
  order: 999;
}

.react-pdf__Document {
  display: contents;
}

.react-pdf__Page {
  width: 100%;
  min-width: 100%;
  overflow: hidden;
  &__svg {
    scroll-snap-align: start;
    display: block;
    width: auto !important;
    height: 100% !important;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    object-fit: contain;
    order: 1;

    svg {
      width: auto;
      height: 100%;
      max-width: 100%;
      display: block;
      margin: auto;
    }

    @include on-dark-theme {
      filter: invert(90%);
    }

    @include on-sepia-theme {
      filter: sepia(1);
    }
  }

  @media screen and (orientation: landscape) {
    text-align: center;
    &:not(:only-child) {
      width: 50%;
      min-width: 50%;
      .react-pdf__Page__svg {
        min-width: 50%;
      }
    }
  }
}
