#menubar {
  &[hidden] {
    display: none;
  }
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0.5rem;
  right: 0.5rem;
  width: 12rem;
  max-width: 100vw;
  background-color: var(--color-bg-popup);
  box-shadow: 0 1px 12px hsla(0, 0, 0, 0.2);
  z-index: 20;
  border-radius: 2px;

  &.hidden {
    display: none;
  }
}

@mixin menu-m-interaction {
  @include reveal;
  @include rippleable;
}

#menubar .menu_item {
  padding: 1rem 0.8rem;
  line-height: 1;
  @include menu-m-interaction();
}

#menu-close-button {
  @include icon-wrapper();
  @include menu-m-interaction();
  align-self: flex-end;
}
