.mob_tab_list {
  position: fixed;
  top: 1.5rem;
  right: 4rem;
  z-index: 20;
  width: 15rem;
  background-color: var(--color-bg-popup);
  box-shadow: 0 1px 12px hsla(0, 0, 0, 0.4);
  border-radius: 2px;

  .tab {
    display: flex;
    @include rippleable;
    @include reveal;

    &_name {
      flex-shrink: 1;
      flex-grow: 1;
      padding: 0;
      line-height: 1;
    }

    &_close_button {
      flex-shrink: 0;
      flex-grow: 0;
      margin: -1rem -1rem -1rem 0;
      @include icon_wrapper;
      @include rippleable;
      @include reveal;
    }
  }
}
