body {
  color: #333;
  --color-text-hint: #00000080;
  --color-accent: #ed7845;
  --color-bg: white;
  --color-bg-popup: var(--color-bg);
  --color-bg-hover: #f6f6f6;
  --color-ripple: #ddd;
  background-color: var(--color-bg);
  font-family: sans-serif;
}

body[data-uitheme="dark"] {
  --color-text-hint: #ffffff80;
  --color-accent: #e85617;
  --color-bg: #1a1a1a;
  --color-bg-popup: #222;
  --color-bg-hover: #2a2a2a;
  --color-ripple: #333;
  color: #ddd;
}

body[data-uitheme="sepia"] {
  --color-bg: #fffce8;
  color: #504222;
  --color-bg-hover: #f3efd6;
  --color-ripple: #e6e1c4;
}

:-webkit-any(body[data-uitheme="dark"] a:any-link, body[data-uitheme="dark"] a:-webkit-any-link) {
  color: #9e9efa;
}

:is(body[data-uitheme="dark"] a:any-link, body[data-uitheme="dark"] a:any-link) {
  color: #9e9efa;
}

.loading_spinner {
  border-radius: 20px;
  width: 40px;
  height: 40px;
  animation: 2s ease-in-out infinite spin;
  position: fixed;
  top: calc(50vh - 20px);
  left: calc(50vw - 20px);
}

.loading_spinner_dot {
  opacity: .4;
  background-color: currentColor;
  border-radius: 4px;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 16px;
  left: 16px;
}

.loading_spinner_dot:first-child {
  top: 6px;
  left: 16px;
}

.loading_spinner_dot:nth-child(2) {
  top: 16px;
  left: 6px;
}

.loading_spinner_dot:nth-child(3) {
  top: 26px;
  left: 16px;
}

.loading_spinner_dot:nth-child(4) {
  top: 16px;
  left: 26px;
}

@keyframes spin {
  0% {
    transform: scale(1)rotate(0);
  }

  50% {
    transform: scale(2)rotate(180deg);
  }

  100% {
    transform: scale(1)rotate(360deg);
  }
}

.toast_wrapper {
  padding: 2rem;
  position: fixed;
  bottom: 0;
  right: 0;
}

.toast_wrapper .toast {
  color: var(--color-bg);
  box-sizing: border-box;
  background-color: #0009;
  border-radius: 2px;
  padding: 1rem;
  transition: all .5s ease-in-out;
  transform: translate(0);
  box-shadow: 0 1px 8px #0003;
}

body[data-uitheme="dark"] .toast_wrapper .toast {
  background-color: #fff9;
}

.toast_wrapper .toast.hidden {
  transform: translate(100vw);
}

svg.icon {
  stroke-width: 4px;
  width: 1.2rem;
  height: 1.2rem;
}

.icon.arrow {
  stroke: currentColor;
  stroke-linejoin: round;
  fill: none;
}

.icon.hamburger, .icon.cross {
  stroke: currentColor;
  stroke-linecap: round;
}

.icon.tabs {
  stroke: currentColor;
  stroke-linecap: round;
  fill: none;
}

#account_info:not([hidden]) {
  margin-right: 1rem;
  display: flex;
}

#account_name {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 10;
  padding: 1rem;
  line-height: 1;
  overflow: hidden;
}

#account_name:empty {
  display: none;
}

#account_inout_button {
  appearance: none;
  font-size: inherit;
  color: inherit;
  background-color: #0000;
  border: none;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 1rem;
  line-height: 1;
  text-decoration: underline;
  display: block;
}

#account_inout_button:focus {
  background-color: var(--color-accent-faint);
}

.signin_button_wrapper {
  margin: 1rem;
}

.signin_button_wrapper .signin_button {
  appearance: none;
  font-size: inherit;
  color: var(--color-text-hint);
  background-color: #fff;
  border: none;
  border-radius: 2px;
  align-items: center;
  margin: 2rem auto;
  font-size: 1.3rem;
  text-decoration: none;
  display: flex;
  box-shadow: 0 0 2px #0003, 0 2px 3px #0000001a;
}

.signin_button_wrapper .signin_button:hover {
  background-color: var(--color-bg-hover);
}

.signin_button_wrapper .signin_button .signin_button_icon {
  background-color: #fff;
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  margin: .2rem 0;
  padding: .5rem;
}

.signin_button_wrapper .signin_button .signin_button_text {
  flex-grow: 1;
  padding: 0 2rem;
}

body[data-uitheme="dark"] .signin_button_wrapper .signin_button {
  color: #fff;
  background-color: #4285f4;
}

body[data-uitheme="dark"] .signin_button_wrapper .signin_button:hover {
  background-color: #5295ff;
}

#container_albums {
  overflow-y: scroll;
}

#container_albums::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

#container_albums::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 2px;
}

body[data-uitheme="dark"] #container_albums::-webkit-scrollbar-thumb {
  background-color: #666;
}

#container_albums > .album {
  padding: 0 1rem;
}

@media screen and (width >= 40rem) {
  #container_albums {
    grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr));
    gap: 1rem;
    padding: 1rem;
    display: grid;
  }

  #container_albums .album {
    background-color: var(--color-bg);
    border-radius: 3px;
    transition: transform .2s ease-out;
    transform: scale(1);
    box-shadow: 0 0 12px #0000001a;
  }

  #container_albums .album:hover {
    transition-duration: .1s;
    transform: scale(1.005);
    box-shadow: 0 2px 16px #00000026;
  }
}

#container_albums > .album {
  color: inherit;
  padding: 1rem;
  font-size: 1.4rem;
  text-decoration: none;
  transition: background-color 75ms ease-out;
  display: block;
}

#container_albums > .album.ripple-active {
  background-color: var(--color-ripple);
}

#language_wrapper {
  text-align: center;
  margin-top: 1rem;
}

#language {
  display: inline-flex;
}

#language .selection {
  border-radius: 2px;
  margin: 0 .5rem;
  padding: .5rem 1rem;
}

#language .selection:not(.active) {
  color: #555;
}

#language .selection[data-active="true"] {
  background-color: var(--color-accent);
  color: #fff;
}

#language .selection[data-active="false"]:hover {
  background-color: #d6d6d6;
}

body[data-uitheme="sepia"] #language .selection[data-active="false"]:hover {
  background-color: #64500033;
}

.nend_ad {
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  margin: .5rem;
  padding: .5rem;
}

.nend_ad_def {
  color: #ddd;
  background-color: #555;
  border-radius: 2px;
  padding: .3rem;
  display: inline-block;
}

.nend_ad_head {
  text-decoration: none;
  display: block;
}

.nend_ad_head_line {
  text-decoration: underline;
}

.nend_ad_head_url {
  color: #777;
}

.nend_ad_body {
  color: #666;
}

#home_new_album {
  box-sizing: border-box;
  background-color: #fafafa;
  width: calc(100vw - 2rem);
  max-width: 48rem;
  margin: 1rem auto;
  padding: 1rem;
}

body[data-uitheme="dark"] #home_new_album {
  background-color: #222;
}

body[data-uitheme="sepia"] #home_new_album {
  background-color: #64500033;
}

#home_new_album summary {
  margin: -1rem;
  padding: 1rem;
}

#home_new_album > details[open] > summary {
  margin: -1rem -1rem 0;
}

#home_new_album_input {
  appearance: none;
  width: available;
  background-color: #0000;
  border: 1px solid #555;
  border-radius: 2px;
  width: -webkit-fill-available;
  margin: 1rem 0;
  padding: .8rem;
  display: block;
}

#home_new_album_button {
  appearance: none;
  font-size: inherit;
  color: #ddd;
  box-sizing: border-box;
  width: available;
  background-color: #445;
  border: none;
  width: -webkit-fill-available;
  max-width: 30rem;
  padding: .8rem;
  display: block;
}

#home_new_album_button:disabled {
  opacity: .6;
}

#home {
  min-height: 100vh;
}

#home.hidden {
  display: none;
}

@media screen and (width >= 40rem) {
  #home {
    background-color: #00000003;
  }
}

#home_heading {
  align-items: baseline;
  display: flex;
}

#home_heading_text {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 1rem;
  font-size: 1.4rem;
  font-weight: bold;
  overflow: hidden;
}

#home_heading #account_info {
  flex-grow: 0;
}

@media screen and (width >= 40rem) {
  #home_heading_text {
    font-size: 1.6rem;
  }
}

#link_to_about_wrapper {
  text-align: center;
  margin: 1rem 0;
}

#mainview {
  grid-template-rows: 3rem 1fr;
  min-height: 100vh;
  display: grid;
}

#mainview.hidden {
  display: none;
}

#main_contents {
  min-height: 0;
  position: relative;
}

.tabbar {
  z-index: 15;
  background-color: #ddd;
  align-items: flex-start;
  max-width: 100vw;
  display: flex;
  position: sticky;
  top: 0;
}

@media screen and (width <= 40rem) {
  .tabbar {
    background-color: var(--color-bg);
    box-shadow: 0 0 6px #0006;
  }
}

body[data-uitheme="dark"] .tabbar {
  background-color: #333;
}

body[data-uitheme="sepia"] .tabbar {
  background-color: #dbd2c2;
}

.tabbar .tab {
  --color-ripple: var(--color-bg);
  flex-grow: 0;
  flex-shrink: 0;
  padding: 1rem .8rem;
  line-height: 1;
  text-decoration: none;
  display: flex;
  color: inherit !important;
}

@media screen and (width >= 40rem) {
  .tabbar .tab {
    transition: background-color 75ms ease-out;
  }

  .tabbar .tab:hover {
    background-color: var(--color-bg-hover);
  }

  .tabbar .tab.ripple-active {
    background-color: var(--color-ripple);
  }

  .tabbar .tab:not(.back_home) {
    min-width: 8rem;
  }

  .tabbar .tab[data-active="true"] {
    background-color: var(--color-bg);
  }
}

.tabbar .tab > .tab_name {
  margin-right: .8rem;
}

.tabbar .tab > .tab_close {
  color: #444;
  width: 1rem;
  height: 1rem;
  margin-left: auto;
  padding: 0;
  transition: background-color 75ms ease-out;
}

body[data-uitheme="dark"] .tabbar .tab > .tab_close {
  color: #ccc;
}

.tabbar .tab > .tab_close:hover {
  background-color: var(--color-bg-hover);
}

.tabbar .tab > .tab_close.ripple-active {
  background-color: var(--color-ripple);
}

.tabbar .tab > .tab_close > svg.icon {
  width: 1rem;
  height: 1rem;
}

@media screen and (width <= 40rem) {
  .tabbar .tab > .tab_close {
    display: none;
  }
}

.tabbar .back_home {
  color: #444;
  flex-shrink: 0;
  width: 1.2rem;
  height: 1.2rem;
  padding: .9rem;
}

body[data-uitheme="dark"] .tabbar .back_home {
  color: #ccc;
}

@media screen and (width <= 40rem) {
  .tabbar .tab_album_name:not(.active) {
    display: none;
  }
}

.menu-open-button {
  color: #444;
  width: 1.2rem;
  height: 1.2rem;
  padding: .9rem;
  transition: background-color 75ms ease-out;
}

body[data-uitheme="dark"] .menu-open-button {
  color: #ccc;
}

.menu-open-button:hover {
  background-color: var(--color-bg-hover);
}

.menu-open-button.ripple-active {
  background-color: var(--color-ripple);
}

.tab_tune_container {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  display: flex;
  overflow-x: scroll;
}

@media screen and (width <= 40rem) {
  .tab_tune_container .tab:not(.active) {
    display: none;
  }
}

.tab_tune_container::-webkit-scrollbar {
  display: none;
}

.mob_tablist_open_button {
  transition: background-color 75ms ease-out;
}

.mob_tablist_open_button.ripple-active {
  background-color: var(--color-ripple);
}

.mob_tablist_open_button:hover {
  background-color: var(--color-bg-hover);
}

@media screen and (width >= 40rem) {
  .mob_tablist_open_button {
    display: none;
  }
}

@media screen and (width <= 40rem) {
  .mob_tablist_open_button {
    color: #444;
    width: 1.2rem;
    height: 1.2rem;
    padding: .9rem;
  }

  body[data-uitheme="dark"] .mob_tablist_open_button {
    color: #ccc;
  }
}

#menubar {
  background-color: var(--color-bg-popup);
  z-index: 20;
  border-radius: 2px;
  flex-direction: column;
  width: 12rem;
  max-width: 100vw;
  display: flex;
  position: fixed;
  top: .5rem;
  right: .5rem;
  box-shadow: 0 1px 12px #0003;
}

#menubar[hidden], #menubar.hidden {
  display: none;
}

#menubar .menu_item {
  padding: 1rem .8rem;
  line-height: 1;
  transition: background-color 75ms ease-out;
}

#menubar .menu_item:hover {
  background-color: var(--color-bg-hover);
}

#menubar .menu_item.ripple-active {
  background-color: var(--color-ripple);
}

#menu-close-button {
  color: #444;
  align-self: flex-end;
  width: 1.2rem;
  height: 1.2rem;
  padding: .9rem;
  transition: background-color 75ms ease-out;
}

body[data-uitheme="dark"] #menu-close-button {
  color: #ccc;
}

#menu-close-button:hover {
  background-color: var(--color-bg-hover);
}

#menu-close-button.ripple-active {
  background-color: var(--color-ripple);
}

.mob_tab_list {
  z-index: 20;
  background-color: var(--color-bg-popup);
  border-radius: 2px;
  width: 15rem;
  position: fixed;
  top: 1.5rem;
  right: 4rem;
  box-shadow: 0 1px 12px #0006;
}

.mob_tab_list .tab {
  transition: background-color 75ms ease-out;
  display: flex;
}

.mob_tab_list .tab.ripple-active {
  background-color: var(--color-ripple);
}

.mob_tab_list .tab:hover {
  background-color: var(--color-bg-hover);
}

.mob_tab_list .tab_name {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0;
  line-height: 1;
}

.mob_tab_list .tab_close_button {
  color: #444;
  flex-grow: 0;
  flex-shrink: 0;
  width: 1.2rem;
  height: 1.2rem;
  margin: -1rem -1rem -1rem 0;
  padding: .9rem;
  transition: background-color 75ms ease-out;
}

body[data-uitheme="dark"] .mob_tab_list .tab_close_button {
  color: #ccc;
}

.mob_tab_list .tab_close_button.ripple-active {
  background-color: var(--color-ripple);
}

.mob_tab_list .tab_close_button:hover {
  background-color: var(--color-bg-hover);
}

body {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

body::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 2px;
}

body[data-uitheme="dark"] body::-webkit-scrollbar-thumb {
  background-color: #666;
}

#album_indices {
  box-shadow: 0 0 6px #0003;
}

#album_indices:empty {
  height: 3rem;
}

@media screen and (width <= 40rem) {
  #album_tune_list {
    position: relative;
  }

  #album_tune_list > .tabbar {
    box-shadow: none;
  }

  #album_tune_list > #album_indices {
    box-shadow: none;
    box-sizing: border-box;
    flex-direction: column;
    justify-items: center;
    width: 3rem;
    position: fixed;
    bottom: 0;
    right: 0;
  }

  #album_tune_list > #album_indices .album_index_jump {
    font-size: .75rem;
    display: block;
  }
}

#album_tune_list {
  position: relative;
}

#album_tune_list.hidden {
  display: none;
}

#album_tune_list #account_info {
  margin-top: calc(50vh - 3rem);
}

#album_tune_list .tabbar {
  height: 3rem;
  position: sticky;
  top: 0;
}

#album_indices {
  background-color: var(--color-bg);
  z-index: 10;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding-left: .3rem;
  padding-right: .3rem;
  position: sticky;
  top: 3rem;
  overflow: scroll;
}

#album_indices::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

#album_indices::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 2px;
}

body[data-uitheme="dark"] #album_indices::-webkit-scrollbar-thumb {
  background-color: #666;
}

@media screen and (width <= 40rem) {
  #album_indices {
    padding-top: .1rem;
  }
}

.album_index_jump {
  text-align: center;
  border-radius: 50%;
  min-width: 1rem;
  margin: .2rem 0;
  padding: .5rem;
  line-height: 1;
  transition: background-color 75ms ease-out;
  display: inline-block;
}

.album_index_jump:hover {
  background-color: var(--color-bg-hover);
}

.album_index_jump.ripple-active {
  background-color: var(--color-ripple);
}

#album_container_tunes {
  min-height: 0;
  padding-bottom: 4rem;
}

#album_container_tunes .tune_wrap {
  position: relative;
}

#album_container_tunes .tune_wrap .tune {
  line-height: 1;
}

#album_container_tunes .tune_wrap .tune .tune_name {
  padding: .8rem;
  font-size: 1.2rem;
  transition: background-color 75ms ease-out;
}

#album_container_tunes .tune_wrap .tune .tune_name.ripple-active {
  background-color: var(--color-ripple);
}

#album_container_tunes .tune_wrap .tune .tune_name a {
  color: inherit;
  text-decoration: none;
}

#album_container_tunes .tune_wrap .ctxmenu_wrap {
  background-color: var(--color-bg);
  z-index: 1;
  border-radius: 3px;
  justify-content: end;
  padding: .5rem 0;
  transition: transform .2s ease-out;
  display: flex;
  position: absolute;
  top: 60%;
  left: 16px;
  transform: scale(1);
  box-shadow: 0 0 12px #0000001a;
}

#album_container_tunes .tune_wrap .ctxmenu_wrap:hover {
  transition-duration: .1s;
  transform: scale(1.005);
  box-shadow: 0 2px 16px #00000026;
}

#album_container_tunes .tune_wrap .ctxmenu_wrap .menu_item {
  padding: .8rem;
  line-height: 1;
  transition: background-color 75ms ease-out;
}

#album_container_tunes .tune_wrap .ctxmenu_wrap .menu_item:hover {
  background-color: var(--color-bg-hover);
}

#album_container_tunes .tune_wrap .ctxmenu_wrap .menu_item.ripple-active {
  background-color: var(--color-ripple);
}

#album_container_tunes .tune_wrap .ctxmenu_wrap .menu_item svg.icon {
  margin: -.2rem;
}

#album_container_tunes .section_label {
  color: #555;
  border-top: 2px solid var(--color-bg);
  border-bottom: 2px solid var(--color-bg);
  background-color: #f8f8f8;
  padding: .4rem 0 .4rem .8rem;
  scroll-margin-top: 8rem;
  font-size: .8rem;
}

body[data-uitheme="dark"] #album_container_tunes .section_label {
  color: #ccc;
  background-color: #222;
}

@media screen and (width >= 40rem) {
  #album_container_tunes {
    background-color: #00000003;
    grid-template-columns: repeat(auto-fill, minmax(26rem, 1fr));
    gap: 1rem;
    padding: 1rem 1rem 4rem;
    display: grid;
  }

  #album_container_tunes .tune {
    background-color: var(--color-bg);
    border-radius: 3px;
    grid-column: span 1;
    transition: transform .2s ease-out;
    transform: scale(1);
    box-shadow: 0 0 12px #0000001a;
  }

  #album_container_tunes .tune:hover {
    transition-duration: .1s;
    transform: scale(1.005);
    box-shadow: 0 2px 16px #00000026;
  }

  #album_container_tunes .tune .tune_name {
    padding: 1rem !important;
  }

  #album_container_tunes .tune .ctxmenu {
    margin-bottom: .8rem;
  }

  #album_container_tunes .section_label {
    background-color: #0000;
    border: none;
    grid-column: 1 / -1;
    font-size: 1.4rem;
    font-weight: bold;
  }
}

#scores_container {
  z-index: 0;
  min-height: 0;
  position: absolute;
  inset: 0;
  overflow-y: hidden;
}

.popup_message {
  color: var(--color-bg);
  background-color: #0009;
  padding: 1rem;
  font-size: 3rem;
  transition: all .2s ease-out;
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
}

body[data-uitheme="dark"] .popup_message {
  background-color: #fff9;
}

.popup_message.hidden {
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%);
}

.score {
  background-color: var(--color-bg);
  -ms-scroll-snap-type: proximity;
  -webkit-scroll-snap-type: proximity;
  scroll-snap-type: x mandatory;
  flex-wrap: nowrap;
  height: 100%;
  display: flex;
  position: relative;
  overflow-x: scroll;
}

.score[hidden] {
  display: none;
}

.score .score_loading_spinner_wrapper {
  order: 998;
  min-width: 100%;
  position: relative;
}

@media screen and (orientation: landscape) {
  .score > .svg_wrapper + .score .score_loading_spinner_wrapper {
    min-width: 50%;
    max-width: 50%;
  }
}

.score .score_loading_spinner_wrapper .loading_spinner {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

.score::-webkit-scrollbar {
  width: 1px;
}

.score_jump_forward, .score_jump_back {
  background-color: #fafafa;
  flex-shrink: 0;
  width: 5rem;
  margin: 1rem .5rem;
}

body[data-uitheme="dark"] .score_jump_forward, body[data-uitheme="dark"] .score_jump_back {
  background-color: #222;
}

.score_jump_back {
  order: 0;
}

.score_jump_forward {
  order: 999;
}

.react-pdf__Document {
  display: contents;
}

.react-pdf__Page {
  width: 100%;
  min-width: 100%;
  overflow: hidden;
}

.react-pdf__Page__svg {
  scroll-snap-align: start;
  object-fit: contain;
  order: 1;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: auto !important;
  height: 100% !important;
}

.react-pdf__Page__svg svg {
  width: auto;
  max-width: 100%;
  height: 100%;
  margin: auto;
  display: block;
}

body[data-uitheme="dark"] .react-pdf__Page__svg {
  filter: invert(90%);
}

body[data-uitheme="sepia"] .react-pdf__Page__svg {
  filter: sepia();
}

@media screen and (orientation: landscape) {
  .react-pdf__Page {
    text-align: center;
  }

  .react-pdf__Page:not(:only-child) {
    width: 50%;
    min-width: 50%;
  }

  .react-pdf__Page:not(:only-child) .react-pdf__Page__svg {
    min-width: 50%;
  }
}
/*# sourceMappingURL=index.6b40f630.css.map */
