#mainview {
  display: grid;
  grid-template-rows: 3rem 1fr;
  min-height: 100vh;

  &.hidden {
    display: none;
  }
}

#main_contents {
  position: relative;
  min-height: 0;
}
